<template>
  <div class="yzjy_box">
    <share class="share"/>
    <h1>Dean’s Message</h1>
    <div class="yzjy_nav" ref="yzjy_nav">
      <div class="left_img"><img :src="baseUrl+imgurl" alt=""></div>
      <div class="right_text">
          <img src="@/assets/“.png" alt="">
          <nav v-html="htmltext"></nav>
          <span>{{author_en}}</span>
      </div>
    </div>  
  </div>
  </template>
  
  <script setup>
  import share from '@/components/share'
  import { get_article_list } from '@/api/API.js'
  import {ref,onMounted} from 'vue'
  import { useRouter,useRoute, } from 'vue-router'
  import base64_ from '@/hooks/useBase64text.js'
  const route = useRoute()
  const baseUrl = process.env.VUE_APP_BASE_URL
  const yzjy_nav = ref(null)
  let htmltext = ref('')
  let author_en = ref('')
  let imgurl = ref('')
  // 根据路由传的id获取文章详情
  let get_Navaeticlexq = async (Id) => {
    const {data:res} = await get_article_list(Id)
    if(res.code == 200){
      htmltext.value = res.data.data[0].content_en
      author_en.value = res.data.data[0].author_en
      imgurl.value = res.data.data[0].thumb
    }
  }
  get_Navaeticlexq({ type_id: route.params.id,is_en:1 })
  onMounted(() => {
  })
  </script>
  
  <style lang="less" scoped>
  @maxw:128rem;
  .yzjy_box{
    position: relative;
    width: 100%;
    font-size: 15/@maxw;
    // margin: 0 30/@maxw;
    opacity: 1;
    h1{
      box-sizing: border-box;
      font-size: 39/@maxw;
      font-family: Georgia;
      font-weight: bolder;
      color: #174994;
      border-bottom:2/@maxw solid #174994;
      margin: 0;
      padding: 0 0 20/@maxw 0;
      text-align: center;
    }
    >.yzjy_nav{ 
      width: 100%;
      margin: 48/@maxw 0 0 0;
      display: flex;
      opacity: 1;
      .left_img{
        position: relative;
        width: 293/@maxw;
        height: 434/@maxw;
        background-image: url("../../../../assets/yzjy_background.png");
        background-size: 100% 100%;
        img{ 
          position: absolute;
          width: 300/@maxw;
          height: 444/@maxw;
          top: 20/@maxw;
          left: 20/@maxw;
        }
      }
      .right_text{
        flex: 1;
        margin-left: 80/@maxw;
        position: relative;
        img{
          position: absolute;
          z-index: -99;
          width: 147/@maxw;
          height: 139/@maxw;
        }
        >nav{
          margin: 50/@maxw 0 0 0;
          padding: 0;
          font-size: 22/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #393939;
          line-height: 40/@maxw;
        }
        >span{
          position: absolute;
          bottom: -80/@maxw;
          left: 0/@maxw;
          font-size: 32/@maxw;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          color: #5A5A5A;
        }
      }
    }
    .share{
      position: absolute;
      right: -161/@maxw;
      top: 120/@maxw;
    }
  }
  
  //  @media screen and (max-width:750px) {
  //   @maxw_:750/100vw;
  //   .yzjy_box{
  //     h1{
  //       font-size: 35/@maxw_ !important;
  //     }
  //     .yzjy_nav{
  //       flex-direction: column;
  //       align-items: center;
  //       .left_img{
  //         width: 300/@maxw_ !important;
  //           height: 444/@maxw_ !important;
  //         >img{
  //           left: 20/@maxw_ !important;
  //           width: 300/@maxw_ !important;
  //           height: 444/@maxw_ !important;
  //         }
  //       }
  //       .right_text {
  //         margin: 0 !important;
  //         >img{
  //           z-index: 0;
  //           top: 50/@maxw_ !important;
  //           left: 50/@maxw_ !important;
  //         }
  //         >nav{
  //           margin-top: 60/@maxw_ !important;
  //           font-size: 30/@maxw_ !important;
  //         }
  //         >span{
  //           font-size: 40/@maxw_;
  //         }
  //       }
  //     }
  //   }
  //  }
  
  </style>